import React from "react"
import ErrorPage from "../components/error"
import CabinsFullWidth from "../components/cabinsFullWidth"

import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <ErrorPage />
      <CabinsFullWidth/>
    </Layout>
  )
}

export default NotFoundPage
