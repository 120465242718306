import React from 'react'
import styled from "styled-components"
import PageHeader from "../elements/pageHeader"
import {Link} from "gatsby"

const ErrorPage = () => {

  return (
    <>
      <PageHeader header="Page Not Found" subHeader="We're sorry, it appears this page no longer exists." />
      <LocationContainer>
        <ContactInfo>
              <p>To inquire about reservations and vacancies please e-mail, phone or visit the <Link to="/reservations">reservations page</Link>.</p>
              <ul>
                <li>Phone: <a href="tel:250-725-3789">250-725-3789</a></li>
                <li>E-mail: <a href="mailto:info@goldcoasttofino.com">info@goldcoasttofino.com</a></li>
              </ul>
            </ContactInfo>
      </LocationContainer>
    </>
  )
}

export default ErrorPage

const LocationContainer = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  display:flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
`

const ContactInfo = styled.div`
  p{
    font-size: 1.2rem;
  }

  ul {
    padding-left: 5%;
  }

  a {
    text-decoration: none;
    color: #1673b5;
  }
`